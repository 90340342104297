import React, { useState, useEffect } from 'react';
import { FaBell, FaGlobe, FaDiscord, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Settings = ({ darkMode, toggleDarkMode }) => {
  const [webhooks, setWebhooks] = useState({
    a2a: '',
    asinMonitor: '',
    storefront: ''
  });
  const [showWebhooks, setShowWebhooks] = useState(false);

  const handleWebhookChange = (type, value) => {
    setWebhooks(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const handleSaveWebhook = async (type) => {
    try {
      const response = await fetch('/api/discord/webhook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type,
          url: webhooks[type]
        }),
        credentials: 'include'
      });
      
      if (response.ok) {
        alert('Webhook saved successfully');
      } else {
        throw new Error('Failed to save webhook');
      }
    } catch (error) {
      alert('Error saving webhook: ' + error.message);
    }
  };

  const handleDeleteWebhook = async (type) => {
    try {
      const response = await fetch(`/api/discord/webhook/${type}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      
      if (response.ok) {
        setWebhooks(prev => ({
          ...prev,
          [type]: ''
        }));
        alert('Webhook deleted successfully');
      } else {
        throw new Error('Failed to delete webhook');
      }
    } catch (error) {
      alert('Error deleting webhook: ' + error.message);
    }
  };

  useEffect(() => {
    const fetchWebhooks = async () => {
      try {
        const response = await fetch('/api/discord/webhooks', {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setWebhooks({
            a2a: data.webhooks.a2a?.url || '',
            asinMonitor: data.webhooks.asinMonitor?.url || '',
            storefront: data.webhooks.storefront?.url || ''
          });
        }
      } catch (error) {
        console.error('Error fetching webhooks:', error);
      }
    };

    fetchWebhooks();    
  }, []);

  const WebhookInput = ({ type, label }) => (
    <div className="mb-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
      <h4 className="font-medium mb-2 dark:text-white">{label}</h4>
      <div className="flex gap-2">
        <input
          type="text"
          value={webhooks[type]}
          onChange={(e) => handleWebhookChange(type, e.target.value)}
          placeholder="Enter Discord webhook URL"
          className="flex-1 p-2 border rounded-md dark:bg-gray-600 dark:border-gray-500 dark:text-white"
        />
        <button
          onClick={() => handleSaveWebhook(type)}
          className="px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
        >
          Save
        </button>
        {webhooks[type] && (
          <button
            onClick={() => handleDeleteWebhook(type)}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="p-6 max-w-4xl mx-auto space-y-6">
      <h1 className="text-2xl font-bold mb-6 dark:text-white">Settings</h1>
      
      {/* Notification Settings */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <div className="flex items-center gap-2 mb-6">
          <FaBell className="text-xl text-[#8957e5]" />
          <h2 className="text-xl font-semibold dark:text-white">Notification Settings</h2>
        </div>
        
        {/* Discord Integration */}
        <div className="space-y-6">
          <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
            <div className="mb-4">
              <div className="flex items-center gap-2 mb-2">
                <FaDiscord className="text-xl text-[#8957e5]" />
                <h3 className="font-medium dark:text-white">Discord Notifications</h3>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                Set up Discord Notifications for A2A Notifications, ASIN Monitor & Storefront Hunter
              </p>
              {!showWebhooks && (
                <>
                  <button
                    onClick={() => setShowWebhooks(true)}
                    className="px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors mb-4"
                  >
                    Setup
                  </button>

                  {/* Discord Help Section - Moved here */}
                  <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <div className="flex justify-between items-center">
                      <div>
                        <h4 className="font-medium mb-1 dark:text-white">Need Help Setting Up?</h4>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Join our Discord community for support and updates</p>
                      </div>
                      <a 
                        href="https://discord.gg/fSc7kv4hAe"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-4 py-2 bg-[#5865F2] text-white rounded-md hover:bg-[#4752C4] transition-colors flex items-center gap-2"
                      >
                        <FaDiscord className="text-xl" />
                        Join Discord
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>

            {showWebhooks && (
              <>
                <div className="space-y-4">
                  <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                    <h4 className="font-medium mb-3 dark:text-white">Setup Instructions</h4>
                    <ol className="list-decimal list-inside text-sm text-gray-600 dark:text-gray-300 space-y-1 ml-1">
                      <li>Create a discord server and add a new channel</li>
                      <li>Click Edit Channel (the cog)</li>
                      <li>Click Integrations and Create Webhook</li>
                      <li>Click the webhook then Copy Webhook URL</li>
                    </ol>
                  </div>
                  
                  <button
                    onClick={() => setShowWebhooks(!showWebhooks)}
                    className="flex items-center gap-2 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                  >
                    Configure Webhooks
                    {showWebhooks ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                </div>

                {/* Webhook Inputs */}
                <div className="space-y-4 mt-4 border-t border-gray-200 dark:border-gray-700 pt-4">
                  <WebhookInput type="a2a" label="A2A Notifications" />
                  <WebhookInput type="asinMonitor" label="ASIN Monitor" />
                  <WebhookInput type="storefront" label="Storefront Hunter" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Appearance Settings */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <div className="flex items-center gap-2 mb-6">
          <FaGlobe className="text-xl text-[#8957e5]" />
          <h2 className="text-xl font-semibold dark:text-white">Appearance</h2>
        </div>
        
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-medium dark:text-white">Dark Mode</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">Toggle dark mode on or off</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                checked={darkMode} 
                onChange={toggleDarkMode}
                className="sr-only peer" 
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#8957e5]/30 dark:peer-focus:ring-[#8957e5]/30 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#8957e5]"></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
