import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { Sidebar } from './components/Sidebar';
import { DatabaseView } from './components/DatabaseView';
import { NotificationsView } from './components/NotificationsView';
import { MainContent } from './components/MainContent';
import CookieConsent from './components/CookieConsent';
import Login from './Login';
import Dashboard from './Dashboard';
import Settings from './Settings';
import PriceMonitor from './PriceMonitor';
import StorefrontHunter from './StorefrontHunter';
import ProfitHunterLanding from './ProfitHunterLanding.jsx';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = 'https://profithunter.app/api';
axios.defaults.withCredentials = true;

// Set up axios interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.setItem('forceLogout', 'true');  // Set flag instead of redirect
      Cookies.remove('jwt_token');
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

function App() {
  const [selectedOption, setSelectedOption] = useState('dashboard');
  const [darkMode, setDarkMode] = useState(() => {
    const storedPreference = localStorage.getItem('darkMode');
    return storedPreference ? JSON.parse(storedPreference) : false;
  });
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentOption = localStorage.getItem('selectedOption');
    if (currentOption) {
      setSelectedOption(currentOption);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedOption', selectedOption);
  }, [selectedOption]);

  useEffect(() => {
   const checkAuth = async () => {
     if (localStorage.getItem('forceLogout')) {
       localStorage.removeItem('forceLogout');
       setIsAuthenticated(false);
       navigate('/login');
       return;
     }
     try {
       const response = await axios.get(`${API_BASE_URL}/auth/user`, {
         withCredentials: true
       });
       if (response.data && !response.data.error) {
         setIsAuthenticated(true);
         setUserId(response.data.user_id);
       }
     } catch (error) {
       console.error('Auth check failed:', error);
       setIsAuthenticated(false);
     }
     setIsLoading(false);
   };
   checkAuth();
  }, [navigate]);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const handleLogout = async () => {
    try {
      await axios.post(`${API_BASE_URL}/auth/logout`);
      setIsAuthenticated(false);
      setUserId(null);
      Cookies.remove('jwt_token');
      localStorage.clear();
      navigate('/login');  // Using navigate here
    } catch (error) {
      setIsAuthenticated(false);
      setUserId(null);
      Cookies.remove('jwt_token'); 
      localStorage.clear();
      navigate('/login');  // Using navigate here
    }
  };

  const toggleDarkMode = () => setDarkMode(!darkMode);
  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  const PrivateRoute = ({ children }) => {
    if (isLoading) return <div>Loading...</div>;
    return isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} />;
  };

  return (
    <div className={`app ${darkMode ? 'dark' : 'light'}`}>
      <ToastContainer />
      <Routes>
        {/* Landing page as the root route */}
        <Route path="/" element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <ProfitHunterLanding />
        } />
        
        {/* Login route */}
        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <Login />
        } />
        
        {/* Protected dashboard route */}
        <Route path="/dashboard" element={
          <PrivateRoute>
            <div className="app-container">
              <Sidebar 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                userId={userId}
              />
              <MainContent 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                userId={userId}
                darkMode={darkMode}
              />
            </div>
          </PrivateRoute>
        } />

        {/* Settings route */}
        <Route path="/settings" element={
          <PrivateRoute>
            <div className="app-container">
              <Sidebar 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                userId={userId}
              />
              <Settings darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            </div>
          </PrivateRoute>
        } />

        {/* Price Monitor route */}
        <Route path="/price-monitor" element={
          <PrivateRoute>
            <div className="app-container">
              <Sidebar 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                userId={userId}
              />
              <PriceMonitor darkMode={darkMode} />
            </div>
          </PrivateRoute>
        } />

        {/* Storefront Hunter route */}
        <Route path="/storefront-hunter" element={
          <PrivateRoute>
            <div className="app-container">
              <Sidebar 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                userId={userId}
              />
              <StorefrontHunter darkMode={darkMode} />
            </div>
          </PrivateRoute>
        } />

        {/* Catch all route - redirect to landing page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <CookieConsent />
    </div>
  );
}

export default App;
