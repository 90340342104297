// src/components/DatabaseView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  FaChartLine, 
  FaShoppingCart, 
  FaSort, 
  FaSortUp, 
  FaSortDown, 
  FaPlus,
  FaSpinner,
  FaSearch,
  FaTimes,
  FaDatabase
} from 'react-icons/fa';
import PurchaseModal from '../PurchaseModal';

const API_BASE_URL = 'https://profithunter.app/api';

export const DatabaseView = ({ userId, setTotalResults }) => {
  // State management
  const [databaseResults, setDatabaseResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(100);
  const [allResults, setAllResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [selectedAsin, setSelectedAsin] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    category: '',
    minProfit: '',
    minROI: '',
    minMonthlySales: '',
    maxASINRank: '',
    minKeepaDrops: '', // Added new filter for minimum Keepa drops
  });
  const [categories, setCategories] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [sortField, setSortField] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalResults, setLocalTotalResults] = useState(0);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const sourceOptions = [
    'Amazon UK',
    'Amazon DE',
    'Amazon FR',
    'Amazon IT',
    'Amazon ES',
    'Amazon US',
    'No Rank DE',
    'No Rank ES',
    'No Rank FR',
    'No Rank DE',
    'No Rank UK',
    'No Rank IT', 
    '3rd Party UK',
    '3rd Party DE',
    '3rd Party FR',
    '3rd Party ES',
    '3rd Party IT',
    'Used UK',
    'Used DE',
    'Used FR',
    'Used ES',
    'Used IT',
    'Business UK'
  ];

  // Currency mapping
  const CURRENCY_MAPPING = {
    'Amazon UK': { symbol: '£', code: 'GBP' },
    'Amazon DE': { symbol: '€', code: 'EUR' },
    'Amazon FR': { symbol: '€', code: 'EUR' },
    'Amazon IT': { symbol: '€', code: 'EUR' },
    'Amazon ES': { symbol: '€', code: 'EUR' },
    'Amazon US': { symbol: '$', code: 'USD' },
    'No Rank UK': { symbol: '£', code: 'GBP' },
    'No Rank DE': { symbol: '€', code: 'EUR' },
    'No Rank FR': { symbol: '€', code: 'EUR' },
    'No Rank IT': { symbol: '€', code: 'EUR' },
    'No Rank ES': { symbol: '€', code: 'EUR' },
    '3rd Party UK': { symbol: '£', code: 'GBP' },
    '3rd Party DE': { symbol: '€', code: 'EUR' },
    '3rd Party FR': { symbol: '€', code: 'EUR' },
    '3rd Party IT': { symbol: '€', code: 'EUR' },
    '3rd Party ES': { symbol: '€', code: 'EUR' },
    'Used UK': { symbol: '£', code: 'GBP' },
    'Used DE': { symbol: '€', code: 'EUR' },
    'Used FR': { symbol: '€', code: 'EUR' },
    'Used IT': { symbol: '€', code: 'EUR' },
    'Used ES': { symbol: '€', code: 'EUR' },
    'Business UK': { symbol: '£', code: 'GBP' }
  };

  // Utility functions
  const formatPrice = (price, source = 'Amazon UK') => {
    const currencyInfo = CURRENCY_MAPPING[source] || CURRENCY_MAPPING['Amazon UK'];
    return `${currencyInfo.symbol}${price?.toFixed(2) || '0.00'}`;
  };

  const getKeepaMarket = (source) => {
    const marketMap = {
      'Amazon DE': '3',
      'Amazon FR': '4',
      'Amazon IT': '8',
      'Amazon ES': '9',
      'Amazon US': '1',
      'Amazon UK': '2',
      'No Rank UK': '2',
      'No Rank DE': '3',
      'No Rank ES': '9',
      'No Rank FR': '4',
      'No Rank IT': '8',
      '3rd Party UK': '2',
      '3rd Party DE': '3',
      '3rd Party ES': '9',
      '3rd Party FR': '4',
      '3rd Party IT': '8',
      'Used UK': '2',
      'Used DE': '3',
      'Used ES': '9',
      'Used FR': '4',
      'Used IT': '8',
      'Business UK': '2'
    };
    return marketMap[source] || '2';
  };

  const getAmazonDomain = (source) => {
    const domainMap = {
      'Amazon DE': 'amazon.de',
      'Amazon FR': 'amazon.fr',
      'Amazon IT': 'amazon.it',
      'Amazon ES': 'amazon.es',
      'Amazon US': 'amazon.com',
      'Amazon UK': 'amazon.co.uk',
      'No Rank UK': 'amazon.co.uk',
      'No Rank DE': 'amazon.de',
      'No Rank ES': 'amazon.es',
      'No Rank FR': 'amazon.fr',
      'No Rank IT': 'amazon.it',
      '3rd Party UK': 'amazon.co.uk',
      '3rd Party DE': 'amazon.de',
      '3rd Party ES': 'amazon.es',
      '3rd Party FR': 'amazon.fr',
      '3rd Party IT': 'amazon.it',
      'Used UK': 'amazon.co.uk',
      'Used DE': 'amazon.de',
      'Used ES': 'amazon.es',
      'Used FR': 'amazon.fr',
      'Used IT': 'amazon.it',
      'Business UK': 'amazon.co.uk'
    };
    return domainMap[source] || 'amazon.co.uk';
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/results`, {
        withCredentials: true
      });
      
      // The response is directly an array of results
      if (Array.isArray(response.data)) {
        setAllResults(response.data);
        setFilteredResults(response.data);
        setLocalTotalResults(response.data.length);
        setTotalResults(response.data.length);
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Received invalid data format from server');
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Failed to fetch results. Please try again.');
    } finally {
      setLoading(false);
    }
  };

// Add this useEffect hook after your fetchInitialData function definition
useEffect(() => {
    fetchInitialData();
    fetchCategories();
}, []); // Empty dependency array means this runs once when component mounts

  useEffect(() => {
    if (!allResults.length) return;

    // Apply filters to all results first
    let filtered = [...allResults];

    // Apply filters
    if (filterOptions.category) {
      filtered = filtered.filter(item => item.category === filterOptions.category);
    }
    if (filterOptions.source) {
      filtered = filtered.filter(item => item.source === filterOptions.source);
    }
    if (filterOptions.minProfit) {
      filtered = filtered.filter(item => item.profit >= parseFloat(filterOptions.minProfit));
    }
    if (filterOptions.minROI) {
      filtered = filtered.filter(item => item.roi_percentage >= parseFloat(filterOptions.minROI));
    }
    if (filterOptions.minMonthlySales) {
      filtered = filtered.filter(item => item.monthly_sales >= parseInt(filterOptions.minMonthlySales));
    }
    if (filterOptions.maxASINRank) {
      filtered = filtered.filter(item => item.asin_rank <= parseInt(filterOptions.maxASINRank));
    }
    if (filterOptions.minKeepaDrops) {
      filtered = filtered.filter(item => item.keepa_drops >= parseInt(filterOptions.minKeepaDrops));
    }
    if (searchTitle) {
      const searchLower = searchTitle.toLowerCase();
      filtered = filtered.filter(item => 
        item.title.toLowerCase().includes(searchLower) || 
        item.asin.toLowerCase().includes(searchLower)
      );
    }

    // Apply sorting
    if (sortField) {
      filtered.sort((a, b) => {
        let aVal = a[sortField];
        let bVal = b[sortField];
        
        if (sortField === 'timestamp') {
          aVal = new Date(aVal).getTime();
          bVal = new Date(bVal).getTime();
        }
        
        if (sortDirection === 'asc') {
          return aVal > bVal ? 1 : -1;
        } else {
          return aVal < bVal ? 1 : -1;
        }
      });
    }

    // Set filtered results and update total count
    setFilteredResults(filtered);
    setLocalTotalResults(filtered.length);
    setTotalResults(filtered.length);
    
    // Reset to first page when filters change
    setCurrentPage(1);
  }, [allResults, filterOptions, searchTitle, sortField, sortDirection]);

  // Handlers
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTitle(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
    setCurrentPage(1);
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setShowPurchaseModal(true);
  };

  const handleAddPurchase = async (purchaseData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/purchases`,
        purchaseData,
        { withCredentials: true }
      );
      console.log('Purchase added:', response.data);
    } catch (error) {
      console.error('Error adding purchase:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories. Please try again.');
    }
  };

  const renderSortIcon = (field) => {
    if (sortField === field) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = filteredResults.slice(indexOfFirstResult, indexOfLastResult);

  // Price Input Modal Component
    // Price Input Modal Component
    const PriceInputModal = ({ isOpen, onClose, onSubmit, asin, source }) => {
      const [desiredPrice, setDesiredPrice] = useState('');
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const parsedPrice = parseFloat(desiredPrice);
        
        if (isNaN(parsedPrice) || parsedPrice <= 0) {
          alert('Please enter a valid price');
          return;
        }
    
        onSubmit(asin, parsedPrice, source);
        setDesiredPrice('');
        onClose();
      };
    
      if (!isOpen) return null;
    
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-[400px] p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Add Price Monitor</h2>
              <button 
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600"
              >
                <FaTimes />
              </button>
            </div>
  
            <div className="mb-6">
              <p className="text-sm text-gray-600 mb-2">ASIN: <span className="font-medium text-gray-900">{asin}</span></p>
              <p className="text-sm text-gray-600">Marketplace: <span className="font-medium text-gray-900">{source}</span></p>
            </div>
  
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Target Price ({CURRENCY_MAPPING[source]?.symbol || '£'})
                </label>
                <input
                  type="number"
                  step="0.01"
                  placeholder="Enter target price"
                  value={desiredPrice}
                  onChange={(e) => setDesiredPrice(e.target.value)}
                  min="0.01"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
                />
              </div>
  
              <div className="flex space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex-1 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7b4fd1]"
                >
                  Add Monitor
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    };

  const handleAddMonitor = async (asin, targetPrice, source) => {
    try {
      const currencyInfo = CURRENCY_MAPPING[source] || CURRENCY_MAPPING['Amazon UK'];
      const response = await axios.post(`${API_BASE_URL}/price-monitor/tasks`, {
        asin,
        target_price: targetPrice,
        source: source,
        currency: currencyInfo.code
      }, {
        withCredentials: true
      });
      console.log('Monitor added successfully:', response.data);
      alert('Price monitor added successfully!');
    } catch (error) {
      console.error('Error adding price monitor:', error);
      alert(`Failed to add price monitor: ${error.response?.data?.error || error.message}`);
    }
  };

  // First, add the KeepaTooltip component after the existing utility functions (getKeepaMarket, getAmazonDomain, etc.)
  // Around line 35, after the utility functions:

  const KeepaTooltip = ({ asin, source }) => {
    const market = getKeepaMarket(source);
    return (
      <div className="absolute z-[9999] p-2 rounded-lg shadow-xl border border-gray-200 hidden group-hover:block bg-white" 
        style={{ 
          right: '100%',
          top: '-125px',
          width: '600px',
          marginRight: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)'
        }}>
        <div className="bg-white w-full h-full">  {/* Added w-full h-full */}
          <img 
            src={`https://graph.keepa.com/pricehistory.png?asin=${asin}&domain=2&width=600&height=250`} 
            alt="Keepa Price History"
            className="w-full h-auto"
            loading="lazy"
          />
        </div>
      </div>
    );
  };

  // Add this function near the top with other utility functions
  const autoSearchPrices = (result) => {
    // Array of search URLs
    const searchUrls = [
      // Google Shopping search
      `https://www.google.com/search?tbm=shop&q=${encodeURIComponent(`${result.title}`)}`,
      // eBay search
      `https://www.ebay.co.uk/sch/i.html?_from=R40&_nkw=${encodeURIComponent(result.title)}&_sacat=0&rt=nc&LH_Complete=1&LH_Sold=1`
    ];

    // Open each search in a new tab
    searchUrls.forEach(url => window.open(url, '_blank'));
  };

  return (
    <div className="a2a-notifications">
      <div className="filter-bar w-full flex flex-wrap gap-4 mb-4 px-4">
        <select 
          name="category" 
          value={filterOptions.category} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        >
          <option value="">All Categories</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
        <select 
          name="source" 
          value={filterOptions.source || ''} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        >
          <option value="">All Sources</option>
          {sourceOptions.map(source => (
            <option key={source} value={source}>
              {source}
            </option>
          ))}
        </select>
        <input 
          type="number" 
          name="minProfit" 
          placeholder="Min Profit" 
          value={filterOptions.minProfit} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="minROI" 
          placeholder="Min ROI %" 
          value={filterOptions.minROI} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="minMonthlySales" 
          placeholder="Min Monthly Sales" 
          value={filterOptions.minMonthlySales} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="maxASINRank" 
          placeholder="Max ASIN Rank" 
          value={filterOptions.maxASINRank} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="minKeepaDrops" 
          placeholder="Min Keepa Drops" 
          value={filterOptions.minKeepaDrops} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="text" 
          placeholder="Search by title or ASIN" 
          value={searchTitle} 
          onChange={handleSearchChange}
          className="flex-1 min-w-[200px] p-2 border rounded"
        />
      </div>

      {loading ? (
        <div className="flex items-center justify-center p-12">
          <FaSpinner className="animate-spin text-4xl text-purple-600" />
        </div>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <div className="notifications-container">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-white border-b border-gray-200">
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider whitespace-nowrap">
                  Time<br/>stamp
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ASIN
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Current<br/>Price
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Avg 90<br/>Day Price
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Profit
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ROI<br/>%
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Monthly<br/>Sales
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ASIN<br/>Rank
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Offer<br/>Count
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Source
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Keepa<br/>Drops
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentResults.map((result, index) => (
                <tr 
                  key={index}
                  onClick={() => handleRowClick({
                    asin: result.asin,
                    title: result.title,
                    current_price: result.current_price,
                    avg_90day_price: result.avg_90day_price,
                    profit: result.profit,
                    roi_percentage: result.roi_percentage,
                    monthly_sales: result.monthly_sales,
                    category: result.category,
                    asin_rank: result.asin_rank,
                    offer_count: result.offer_count,
                    source: result.source,
                    keepa_drops: result.keepa_drops,
                    image_url: result.image_url?.split(',')[0]
                  })}
                  className="hover:bg-gray-50 transition-colors duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap w-[10%]">
                    {new Date(result.timestamp).toLocaleString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false
                    })}
                  </td>
                  <td className="px-6 py-4 w-[5%]">
                    <img 
                      src={`https://images-na.ssl-images-amazon.com/images/I/${result.image_url.split(',')[0]}`} 
                      alt={result.title}
                      className="h-12 w-12 object-contain"
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 w-[25%]">{result.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[8%]">{result.asin}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[7%]">
                    {formatPrice(result.current_price, result.source)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[7%]">
                    £{result.avg_90day_price?.toFixed(2) || '0.00'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    £{result.profit?.toFixed(2) || '0.00'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.roi_percentage.toFixed(2)}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.monthly_sales === null ? '0-49' : result.monthly_sales}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[8%]">
                    {result.category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.asin_rank}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[3%]">
                    {result.offer_count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.source}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[4%]">
                    {result.keepa_drops || '0'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap w-[5%]" onClick={(e) => e.stopPropagation()}>
                    <div className="flex items-center gap-1 justify-start">
                      <a 
                        href={`https://sas.selleramp.com/sas/lookup?asin=${result.asin}&sas_cost_price=${result.current_price}&sas_sale_price=${result.avg_90day_price}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center"
                        title="Analyze with SAS"
                      >
                        <FaDatabase className="w-4 h-4" />
                      </a>
                      <a 
                        href={`https://keepa.com/#!product/${getKeepaMarket(result.source)}-${result.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900 relative group"
                        title="View on Keepa"
                        onClick={(e) => e.stopPropagation()}
                        style={{ position: 'relative' }}
                      >
                        <FaChartLine className="w-4 h-4" />
                        <KeepaTooltip 
                          asin={result.asin}
                          source={result.source}
                        />
                      </a>
                      <a 
                        href={`https://${getAmazonDomain(result.source)}/dp/${result.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-gray-600 hover:text-gray-900"
                        title="View on Amazon"
                      >
                        <FaShoppingCart className="w-4 h-4" />
                      </a>
                      <button
                        onClick={() => {
                          setShowPriceModal(true);
                          setSelectedAsin(result.asin);
                          setSelectedSource(result.source);
                        }}
                        className="flex items-center text-gray-600 hover:text-gray-900"
                        title="Add to Price Monitor"
                      >
                        <FaPlus className="w-4 h-4" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          autoSearchPrices(result);
                        }}
                        className="flex items-center text-gray-600 hover:text-gray-900"
                        title="Auto Search Prices"
                      >
                        <FaSearch className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">
                {Math.min((currentPage - 1) * resultsPerPage + 1, totalResults)}
              </span>
              {' '}-{' '}
              <span className="font-medium">
                {Math.min(currentPage * resultsPerPage, totalResults)}
              </span>
              {' '}of{' '}
              <span className="font-medium">{totalResults}</span>
              {' '}results
            </p>
          </div>
          <div>
            <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                First
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                Page {currentPage} of {Math.ceil(totalResults / resultsPerPage)}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(Math.ceil(totalResults / resultsPerPage), prev + 1))}
                disabled={currentPage === Math.ceil(totalResults / resultsPerPage)}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
              <button
                onClick={() => setCurrentPage(Math.ceil(totalResults / resultsPerPage))}
                disabled={currentPage === Math.ceil(totalResults / resultsPerPage)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Last
              </button>
            </nav>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showPriceModal && (
        <PriceInputModal
          isOpen={showPriceModal}
          onClose={() => setShowPriceModal(false)}
          onSubmit={handleAddMonitor}
          asin={selectedAsin}
          source={selectedSource}
        />
      )}

      {showPurchaseModal && (
        <PurchaseModal
          isOpen={showPurchaseModal}
          onClose={() => setShowPurchaseModal(false)}
          product={selectedProduct}
          onSubmit={handleAddPurchase}
        />
      )}
    </div>
  );
};

export default DatabaseView;
