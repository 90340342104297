import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ShoppingCart, BarChart2, Trash2, Plus, List, RotateCw, X } from 'lucide-react';
import './PriceMonitor.css';

const API_BASE_URL = 'https://profithunter.app/api/price-monitor';

const AMAZON_SOURCES = [
    { value: 'Amazon UK', label: 'Amazon UK 🇬🇧' },
    { value: 'Amazon DE', label: 'Amazon Germany 🇩🇪' },
    { value: 'Amazon IT', label: 'Amazon Italy 🇮🇹' },
    { value: 'Amazon ES', label: 'Amazon Spain 🇪🇸' },
    { value: 'Amazon FR', label: 'Amazon France 🇫🇷' },
];

// Helper function for consistent date formatting
const formatDate = (date) => {
    return new Date(date).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
};

const Modal = ({ children, title, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X className="w-6 h-6" />
                </button>
            </div>
            {children}
        </div>
    </div>
);

// Move AddTaskModal outside of PriceMonitor component, near the top of the file
const AddTaskModal = ({ onClose, onSubmit, newTask, onChange, loading }) => (
    <Modal title="Add Price Monitor Task" onClose={onClose}>
        <form onSubmit={onSubmit} className="space-y-4">
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">ASIN</label>
                <input
                    type="text"
                    name="asin"
                    value={newTask.asin}
                    onChange={onChange}
                    className="w-full p-2 border rounded-md bg-gray-100 text-gray-900"
                    placeholder="Enter ASIN"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Target Price (£)</label>
                <input
                    type="number"
                    name="target_price"
                    step="0.01"
                    value={newTask.target_price}
                    onChange={onChange}
                    className="w-full p-2 border rounded-md bg-gray-100 text-gray-900"
                    placeholder="Enter target price"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Marketplace</label>
                <select
                    name="source"
                    value={newTask.source}
                    onChange={onChange}
                    className="w-full p-2 border rounded-md bg-gray-100 text-gray-900"
                >
                    {AMAZON_SOURCES.map(({ value, label }) => (
                        <option key={value} value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div className="flex gap-2 pt-4">
                <button
                    type="submit"
                    disabled={loading}
                    className="px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors disabled:opacity-50"
                >
                    {loading ? 'Adding...' : 'Add Task'}
                </button>
                <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
                >
                    Cancel
                </button>
            </div>
        </form>
    </Modal>
);

const PriceMonitor = () => {
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [newTask, setNewTask] = useState({
        asin: '',
        target_price: '',
        source: 'Amazon UK'
    });
    const [loading, setLoading] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lastRefresh, setLastRefresh] = useState(null);

    const fetchNotifications = useCallback(async (showLoading = true) => {
        if (showLoading) {
            setNotificationsLoading(true);
        }
        try {
            const response = await axios.get(`${API_BASE_URL}/notifications`, {
                withCredentials: true
            });
            const newNotifications = Array.isArray(response.data) ? response.data : [];
            newNotifications.sort((a, b) => 
                new Date(b.matchTime) - new Date(a.matchTime)
            );
            setNotifications(newNotifications);
            setLastRefresh(new Date());
            setError(null);
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setError('Failed to fetch notifications');
        } finally {
            if (showLoading) {
                setNotificationsLoading(false);
            }
        }
    }, []);
    
    useEffect(() => {
        fetchTasks();
        fetchNotifications();
    }, [fetchNotifications]);
    
    const fetchTasks = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/tasks`, {
                withCredentials: true
            });
            setTasks(response.data);
        } catch (error) {
            console.error('Error fetching tasks:', error);
            setError('Failed to fetch tasks');
        }
    };

    const handleAddTask = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post(`${API_BASE_URL}/tasks`, newTask, {
                withCredentials: true
            });
            setShowAddTaskModal(false);
            setNewTask({ asin: '', target_price: '', source: 'Amazon UK' });
            await fetchTasks();
            setError(null);
        } catch (error) {
            console.error('Error adding task:', error);
            setError('Failed to add task');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTask = async (taskId) => {
        try {
            await axios.delete(`${API_BASE_URL}/tasks/${taskId}`, {
                withCredentials: true
            });
            await fetchTasks();
            setError(null);
        } catch (error) {
            console.error('Error deleting task:', error);
            setError('Failed to delete task');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTask(prevTask => ({
            ...prevTask,
            [name]: value
        }));
    };

    const TasksModal = () => (
        <Modal title="Price Monitor Tasks" onClose={() => setShowTasksModal(false)}>
            <div className="space-y-4">
                {tasks.length === 0 ? (
                    <div className="text-center text-gray-500 py-8">No active monitoring tasks</div>
                ) : (
                    tasks.map((task) => (
                        <div key={task.id} className="flex justify-between items-center p-4 bg-gray-100 rounded-lg">
                            <div className="space-y-1">
                                <div className="font-medium text-gray-900">ASIN: {task.asin}</div>
                                <div className="text-sm text-gray-700">Target Price: £{task.target_price}</div>
                                <div className="text-sm text-gray-700">Marketplace: {task.source}</div>
                                <div className="text-sm text-gray-700">
                                    Created: {formatDate(task.created_at)}
                                </div>
                            </div>
                            <button
                                onClick={() => handleDeleteTask(task.id)}
                                className="text-red-500 hover:text-red-700 transition-colors"
                            >
                                <Trash2 className="w-5 h-5" />
                            </button>
                        </div>
                    ))
                )}
            </div>
        </Modal>
    );

    const NotificationsTable = () => (
        <div className="w-full">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-white border-b border-gray-200">
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider whitespace-nowrap">
                            ASIN
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider w-[400px]">
                            Title
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider whitespace-nowrap">
                            Current<br/>Price
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider whitespace-nowrap">
                            Target<br/>Price
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                            Offers
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                            Category
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                            Rank
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                            Marketplace
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                            Match Time
                        </th>
                        <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {notifications.length === 0 ? (
                        <tr>
                            <td colSpan="10" className="px-6 py-8 text-center text-gray-500">
                                No price notifications found
                            </td>
                        </tr>
                    ) : (
                        notifications.map((notification) => (
                            <tr key={notification.id} className="hover:bg-gray-50 transition-colors duration-200">
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{notification.asin}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-normal break-words">{notification.title}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">£{notification.currentPrice}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">£{notification.targetPrice}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{notification.totalOffers}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-normal break-words">{notification.category}</td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{notification.mainRank}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                        {notification.source}
                                    </span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                                    {formatDate(notification.matchTime)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center gap-3">
                                        <a
                                            href={getAmazonUrl(notification.asin, notification.source)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-600 hover:text-gray-900"
                                        >
                                            <ShoppingCart className="w-5 h-5" />
                                        </a>
                                        <a
                                            href={getKeepaUrl(notification.asin, notification.source)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-600 hover:text-gray-900"
                                        >
                                            <BarChart2 className="w-5 h-5" />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="p-4 space-y-4">
            <div className="flex justify-between items-center">
                <div className="space-x-2">
                    <button
                        onClick={() => setShowAddTaskModal(true)}
                        className="inline-flex items-center px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                    >
                        <Plus className="w-4 h-4 mr-2" /> Add Task
                    </button>
                    <button
                        onClick={() => setShowTasksModal(true)}
                        className="inline-flex items-center px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                    >
                        <List className="w-4 h-4 mr-2" /> View Tasks
                    </button>
                    <button
                        onClick={() => fetchNotifications(true)}
                        className="inline-flex items-center px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                    >
                        <RotateCw className="w-4 h-4 mr-2" /> Refresh
                    </button>
                </div>
                {lastRefresh && (
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                        Last refreshed: {formatDate(lastRefresh)}
                    </div>
                )}
            </div>

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                    {error}
                </div>
            )}

            {notificationsLoading ? (
                <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                </div>
            ) : (
                <div className="notifications-container">
                    <NotificationsTable />
                </div>
            )}

            {showAddTaskModal && (
                <AddTaskModal
                    onClose={() => setShowAddTaskModal(false)}
                    onSubmit={handleAddTask}
                    newTask={newTask}
                    onChange={handleInputChange}
                    loading={loading}
                />
            )}
            {showTasksModal && <TasksModal />}
        </div>
    );
};

const getAmazonUrl = (asin, source) => {
    const domains = {
        'Amazon UK': 'co.uk',
        'Amazon DE': 'de',
        'Amazon IT': 'it',
        'Amazon ES': 'es',
        'Amazon FR': 'fr',
        'Amazon US': 'com'
    };
    const domain = domains[source] || 'co.uk';
    return `https://www.amazon.${domain}/dp/${asin}`;
};

const getKeepaUrl = (asin, source) => {
    const marketplaces = {
        'Amazon UK': '1',
        'Amazon DE': '3',
        'Amazon IT': '4',
        'Amazon ES': '5',
        'Amazon FR': '2',
        'Amazon US': '0'
    };
    const marketplace = marketplaces[source] || '1';
    return `https://keepa.com/#!product/${marketplace}-${asin}`;
};

export default PriceMonitor;
