// src/components/NotificationsView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  FaChartLine, 
  FaShoppingCart, 
  FaPlus, 
  FaTimes, 
  FaTasks,
  FaSpinner,
  FaSearch,
  FaDatabase
} from 'react-icons/fa';
import PurchaseModal from '../PurchaseModal';

const API_BASE_URL = 'https://profithunter.app/api';

// Move these utility functions to the top, before KeepaTooltip
const getKeepaMarket = (source) => {
  const marketMap = {
      'Amazon DE': '3',
      'Amazon FR': '4',
      'Amazon IT': '8',
      'Amazon ES': '9',
      'Amazon US': '1',
      'Amazon UK': '2',
      'No Rank UK': '2',
      'No Rank DE': '3',
      'No Rank ES': '9',
      'No Rank FR': '4',
      'No Rank IT': '8',
      '3rd Party UK': '2',
      '3rd Party DE': '3',
      '3rd Party ES': '9',
      '3rd Party FR': '4',
      '3rd Party IT': '8',
      'Used UK': '2',
      'Used DE': '3',
      'Used ES': '9',
      'Used FR': '4',
      'Used IT': '8',
      'Business UK': '2'
  };
  return marketMap[source] || '2';
};

// Now define KeepaTooltip after the utility function
const KeepaTooltip = ({ asin, source }) => {
  const market = getKeepaMarket(source);
  return (
    <div className="absolute z-[9999] p-2 rounded-lg shadow-xl border border-gray-200 hidden group-hover:block bg-white" 
      style={{ 
        right: '100%',
        top: '-125px',
        width: '600px',
        marginRight: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)'
      }}>
      <div className="bg-white w-full h-full">  {/* Added w-full h-full */}
        <img 
          src={`https://graph.keepa.com/pricehistory.png?asin=${asin}&domain=2&width=600&height=250`} 
          alt="Keepa Price History"
          className="w-full h-auto"
          loading="lazy"
        />
      </div>
    </div>
  );
};

// Add this function near your other utility functions
const autoSearchPrices = (notification) => {
  // Array of search URLs
  const searchUrls = [
    // Google Shopping search
    `https://www.google.com/search?tbm=shop&q=${encodeURIComponent(`${notification.title}`)}`,
    // eBay search
    `https://www.ebay.co.uk/sch/i.html?_from=R40&_nkw=${encodeURIComponent(notification.title)}&_sacat=0&rt=nc&LH_Complete=1&LH_Sold=1`
  ];

  // Open each search in a new tab
  searchUrls.forEach(url => window.open(url, '_blank'));
};

export const NotificationsView = ({ userId, setNotificationCount }) => {
  // State management
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [showChecked, setShowChecked] = useState('all');
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [selectedAsin, setSelectedAsin] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedNotifications, setDisplayedNotifications] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [newTask, setNewTask] = useState({
    taskName: '',
    category: '',
    source: '',
    minProfit: '',
    minROI: '',
    minMonthlySales: '',
    maxASINRank: '',
    keepaDrops: '',
  });
  const [selectedOption, setSelectedOption] = useState('all');
  const [selectedTaskFilter, setSelectedTaskFilter] = useState('all');
  const [selectedSource, setSelectedSource] = useState(null);

  // Currency mapping
  const CURRENCY_MAPPING = {
    'Amazon UK': { symbol: '£', code: 'GBP' },
    'Amazon DE': { symbol: '€', code: 'EUR' },
    'Amazon FR': { symbol: '€', code: 'EUR' },
    'Amazon IT': { symbol: '€', code: 'EUR' },
    'Amazon ES': { symbol: '€', code: 'EUR' },
    'Amazon US': { symbol: '$', code: 'USD' },
    'No Rank UK': { symbol: '£', code: 'GBP' },
    'No Rank DE': { symbol: '€', code: 'EUR' },
    'No Rank FR': { symbol: '€', code: 'EUR' },
    'No Rank IT': { symbol: '€', code: 'EUR' },
    'No Rank ES': { symbol: '€', code: 'EUR' },
    '3rd Party UK': { symbol: '£', code: 'GBP' },
    '3rd Party DE': { symbol: '€', code: 'EUR' },
    '3rd Party FR': { symbol: '€', code: 'EUR' },
    '3rd Party IT': { symbol: '€', code: 'EUR' },
    '3rd Party ES': { symbol: '€', code: 'EUR' },
    'Used UK': { symbol: '£', code: 'GBP' },
    'Used DE': { symbol: '€', code: 'EUR' },
    'Used FR': { symbol: '€', code: 'EUR' },
    'Used IT': { symbol: '€', code: 'EUR' },
    'Used ES': { symbol: '€', code: 'EUR' },
    'Business UK': { symbol: '£', code: 'GBP' }
  };

  // Utility functions
  const formatPrice = (price, source = 'Amazon UK') => {
    const currencyInfo = CURRENCY_MAPPING[source] || CURRENCY_MAPPING['Amazon UK'];
    return `${currencyInfo.symbol}${price?.toFixed(2) || '0.00'}`;
  };

  const getAmazonDomain = (source) => {
    const domainMap = {
      'Amazon DE': 'amazon.de',
      'Amazon FR': 'amazon.fr',
      'Amazon IT': 'amazon.it',
      'Amazon ES': 'amazon.es',
      'Amazon US': 'amazon.com',
      'Amazon UK': 'amazon.co.uk',
      'No Rank UK': 'amazon.co.uk',
      'No Rank DE': 'amazon.de',
      'No Rank ES': 'amazon.es',
      'No Rank FR': 'amazon.fr',
      'No Rank IT': 'amazon.it',
      '3rd Party UK': 'amazon.co.uk',
      '3rd PartyDE': 'amazon.de',
      '3rd Party ES': 'amazon.es',
      '3rd Party FR': 'amazon.fr',
      '3rd PartyIT': 'amazon.it',
      'Used UK': 'amazon.co.uk',
      'Used DE': 'amazon.de',
      'Used ES': 'amazon.es',
      'Used FR': 'amazon.fr',
      'Used IT': 'amazon.it',
      'Business UK': 'amazon.co.uk',
    };
    return domainMap[source] || 'amazon.co.uk';
  };

  // Effects
  useEffect(() => {
    fetchCategories();
    fetchNotifications();
    fetchTasks();
  }, [userId]);

  // API calls
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/notifications`, {
        withCredentials: true
      });
      
      if (!response.data || !response.data.tasks) {
        setNotifications([]);
        return;
      }
      
      const tasksWithNotifications = response.data.tasks.filter(task => 
        task.notifications && task.notifications.length > 0
      );
      
      setNotifications(tasksWithNotifications);
      if (setNotificationCount) {
        setNotificationCount(tasksWithNotifications.length);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks`, {
        withCredentials: true
      });
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      alert('Failed to fetch tasks');
    }
  };

  // Handlers
  const handleToggleCheck = async (notificationId, isChecked) => {
    try {
      const endpoint = isChecked ? 'uncheck' : 'check';
      await axios.post(`${API_BASE_URL}/notifications/${notificationId}/${endpoint}`, {}, {
        withCredentials: true
      });
      
      setNotifications(tasks => 
        tasks.map(task => ({
          ...task,
          notifications: task.notifications.map(notif => 
            notif.id === notificationId 
              ? { ...notif, checked: !isChecked }
              : notif
          )
        }))
      );
    } catch (error) {
      console.error(`Error ${isChecked ? 'unchecking' : 'checking'} notification:`, error);
      alert('Failed to update notification status');
    }
  };

  useEffect(() => {
    if (notifications.length > 0) {
      // Calculate total results from all notifications across all tasks
      const totalNotifs = notifications.reduce((acc, task) => 
        acc + task.notifications.length, 0
      );
      setTotalResults(totalNotifs);
      setTotalPages(Math.ceil(totalNotifs / resultsPerPage));

      // Flatten all notifications from all tasks
      const allNotifications = notifications.flatMap(task => 
        task.notifications.map(notif => ({
          ...notif,
          taskId: task.id,
          taskName: task.name || `Task ${task.id}`,
          taskSource: task.source
        }))
      );

      // Filter notifications based on current filters
      let filteredNotifs = allNotifications;
      
      if (showChecked === 'unchecked') {
        filteredNotifs = filteredNotifs.filter(notif => !notif.checked);
      } else if (showChecked === 'checked') {
        filteredNotifs = filteredNotifs.filter(notif => notif.checked);
      }

      if (selectedTaskFilter !== 'all') {
        filteredNotifs = filteredNotifs.filter(notif => 
          notif.taskId === parseInt(selectedTaskFilter)
        );
      }

      // Sort notifications by timestamp (most recent first)
      filteredNotifs.sort((a, b) => 
        new Date(b.timestamp) - new Date(a.timestamp)
      );

      // Calculate pagination slice
      const startIndex = (currentPage - 1) * resultsPerPage;
      const endIndex = startIndex + resultsPerPage;
      
      // Set displayed notifications for current page
      setDisplayedNotifications(filteredNotifs.slice(startIndex, endIndex));
      setTotalResults(filteredNotifs.length);
      setTotalPages(Math.ceil(filteredNotifs.length / resultsPerPage));
    }
  }, [notifications, currentPage, resultsPerPage, showChecked, selectedTaskFilter]);

  const handleAddMonitor = async (asin, targetPrice, source) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/price-monitor/tasks`, {
        asin,
        target_price: targetPrice,
        source: source
      }, {
        withCredentials: true
      });
      alert('Price monitor added successfully!');
    } catch (error) {
      console.error('Error adding price monitor:', error);
      alert(`Failed to add price monitor: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleDeleteTask = async (taskId) => {
    if (!window.confirm('Are you sure you want to delete this task?')) {
      return;
    }

    try {
      await axios.delete(`${API_BASE_URL}/tasks/${taskId}`, {
        withCredentials: true
      });
      setTasks(tasks.filter(task => task.id !== taskId));
      fetchNotifications();
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Failed to delete task. Please try again.');
    }
  };

  const handleCreateTask = async (e) => {
    e.preventDefault();
    try {
      const taskData = {
        taskName: newTask.taskName,
        category: newTask.category || null,
        source: newTask.source || null,
        minProfit: newTask.minProfit ? parseFloat(newTask.minProfit) : null,
        minROI: newTask.minROI ? parseFloat(newTask.minROI) : null,
        minMonthlySales: newTask.minMonthlySales ? parseInt(newTask.minMonthlySales) : null,
        maxASINRank: newTask.maxASINRank ? parseInt(newTask.maxASINRank) : null,
        keepaDrops: newTask.keepaDrops ? parseInt(newTask.keepaDrops) : null,
      };
  
      const response = await axios.post(`${API_BASE_URL}/tasks`, taskData, {
        withCredentials: true
      });
  
      if (response.status === 201) {
        setTasks(prevTasks => [...prevTasks, {...response.data, notifications: []}]);
        setNewTask({
          taskName: '',
          category: '',
          source: '',
          minProfit: '',
          minROI: '',
          minMonthlySales: '',
          maxASINRank: '',
          keepaDrops: '',
        });
        setShowModal(false);
        fetchNotifications();
      }
    } catch (error) {
      console.error('Error creating task:', error);
      alert('Failed to create task. Please try again.');
    }
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setShowPurchaseModal(true);
  };

  const handleAddPurchase = async (purchaseData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/purchases`,
        purchaseData,
        { withCredentials: true }
      );
      console.log('Purchase added:', response.data);
    } catch (error) {
      console.error('Error adding purchase:', error);
    }
  };

  // Render functions
  const renderNotificationForm = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[500px] p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Add Notification Task</h2>
          <button 
            onClick={() => setShowModal(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            <FaTimes />
          </button>
        </div>
        
        <form onSubmit={handleCreateTask} className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="Task Name"
              value={newTask.taskName}
              onChange={(e) => setNewTask({ ...newTask, taskName: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>

          <div>
            <select
              value={newTask.category}
              onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              <option value="">All Categories</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div>
            <select
              value={newTask.source}
              onChange={(e) => setNewTask({ ...newTask, source: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              <option value="">All Sources</option>
              <option value="Amazon UK">Amazon UK</option>
              <option value="Amazon DE">Amazon DE</option>
              <option value="Amazon IT">Amazon IT</option>
              <option value="Amazon ES">Amazon ES</option>
              <option value="Amazon FR">Amazon FR</option>
              <option value="Amazon US">Amazon US</option>
              <option value="No Rank UK">No Rank UK</option>
              <option value="No Rank DE">No Rank DE</option>
              <option value="No Rank ES">No Rank ES</option>
              <option value="No Rank FR">No Rank FR</option>
              <option value="No Rank IT">No Rank IT</option>
              <option value="3rd Party UK">3rd Party UK</option>
              <option value="3rd Party DE">3rd Party DE</option>
              <option value="3rd Party ES">3rd Party ES</option>
              <option value="3rd Party FR">3rd Party FR</option>
              <option value="3rd Party IT">3rd Party IT</option>
              <option value="Used UK">Used UK</option>
              <option value="Used DE">Used DE</option>
              <option value="Used ES">Used ES</option>
              <option value="Used FR">Used FR</option>
              <option value="Used IT">Used IT</option>
              <option value="Business UK">Business UK</option>
            </select>
          </div>

          <div>
            <input
              type="number"
              placeholder="Min Profit"
              value={newTask.minProfit}
              onChange={(e) => setNewTask({ ...newTask, minProfit: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div>
            <input
              type="number"
              placeholder="Min ROI %"
              value={newTask.minROI}
              onChange={(e) => setNewTask({ ...newTask, minROI: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div>
            <input
              type="number"
              placeholder="Min Monthly Sales"
              value={newTask.minMonthlySales}
              onChange={(e) => setNewTask({ ...newTask, minMonthlySales: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div>
            <input
              type="number"
              placeholder="Max ASIN Rank"
              value={newTask.maxASINRank}
              onChange={(e) => setNewTask({ ...newTask, maxASINRank: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div>
            <input
              type="number"
              placeholder="Keepa Drops"
              value={newTask.keepaDrops}
              onChange={(e) => setNewTask({ ...newTask, keepaDrops: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div className="flex space-x-3 pt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-[#8957e5] text-white rounded-md w-[120px]"
            >
              Add Task
            </button>
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 w-[120px]"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  const renderPagination = () => (
    <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">
              {Math.min((currentPage - 1) * resultsPerPage + 1, totalResults)}
            </span>
            {' '}-{' '}
            <span className="font-medium">
              {Math.min(currentPage * resultsPerPage, totalResults)}
            </span>
            {' '}of{' '}
            <span className="font-medium">{totalResults}</span>
            {' '}results
          </p>
        </div>
        <div>
          <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              First
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              Last
            </button>
          </nav>
        </div>
      </div>
    </div>
  );

  const renderTasks = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[800px] p-6 max-h-[80vh] overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Your Tasks</h2>
          <button 
            onClick={() => setShowTasks(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            <FaTimes />
          </button>
        </div>

        {tasks.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            No tasks found. Create a task to get started.
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Source</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Min Profit</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Min ROI %</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Min Monthly Sales</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Max ASIN Rank</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Keepa Drops</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tasks.map((task) => (
                  <tr key={task.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{task.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{task.taskName || 'Untitled Task'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{task.source || 'All'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{task.category || 'All'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {task.minProfit ? `£${task.minProfit}` : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {task.minROI ? `${task.minROI}%` : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {task.minMonthlySales || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {task.maxASINRank || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {task.keepaDrops || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      <button
                        onClick={() => handleDeleteTask(task.id)}
                        className="text-red-600 hover:text-red-900 font-medium"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <button
            onClick={() => setShowTasks(false)}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 w-[120px]"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

  // Price Input Modal Component
  const PriceInputModal = ({ isOpen, onClose, onSubmit, asin, source }) => {
    const [desiredPrice, setDesiredPrice] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const parsedPrice = parseFloat(desiredPrice);
      
      if (isNaN(parsedPrice) || parsedPrice <= 0) {
        alert('Please enter a valid price');
        return;
      }
  
      onSubmit(asin, parsedPrice, source);
      setDesiredPrice('');
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg w-[400px] p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Add Price Monitor</h2>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <FaTimes />
            </button>
          </div>

          <div className="mb-6">
            <p className="text-sm text-gray-600 mb-2">ASIN: <span className="font-medium text-gray-900">{asin}</span></p>
            <p className="text-sm text-gray-600">Marketplace: <span className="font-medium text-gray-900">{source}</span></p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Target Price ({CURRENCY_MAPPING[source]?.symbol || '£'})
              </label>
              <input
                type="number"
                step="0.01"
                placeholder="Enter target price"
                value={desiredPrice}
                onChange={(e) => setDesiredPrice(e.target.value)}
                min="0.01"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              />
            </div>

            <div className="flex space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex-1 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7b4fd1]"
              >
                Add Monitor
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="a2a-notifications">
      <div className="top-bar">
        <div className="button-group">
          <button 
            className="inline-flex items-center px-4 py-2 bg-[#8957e5] text-white rounded-md" 
            onClick={() => setShowModal(true)}
          >
            <FaPlus className="mr-3" /> Add Task
          </button>
          <button 
            className="inline-flex items-center px-4 py-2 bg-[#8957e5] text-white rounded-md"
            onClick={() => setShowTasks(true)}
          >
            <FaTasks className="mr-3" /> View Tasks
          </button>
          <button 
            className={`inline-flex items-center px-4 py-2 bg-[#8957e5] text-white rounded-md ${showChecked === 'unchecked' ? 'active' : ''}`}
            onClick={() => {
              setShowChecked(showChecked === 'unchecked' ? 'all' : 'unchecked');
              setSelectedOption(showChecked === 'unchecked' ? 'all' : 'unchecked');
            }}
          >
            {showChecked === 'unchecked' ? 'Show All' : 'Show Notifications'}
          </button>
          
          <select
            value={selectedTaskFilter}
            onChange={(e) => setSelectedTaskFilter(e.target.value)}
            className="px-4 py-2 bg-white border border-gray-300 rounded-md ml-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <option value="all">All Tasks</option>
            {tasks.map(task => (
              <option key={task.id} value={task.id}>
                {task.taskName} ({task.source || 'All Sources'})
              </option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex items-center justify-center p-12">
          <FaSpinner className="animate-spin text-4xl text-purple-600" />
        </div>
      ) : (
        <div className="notifications-container">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-white border-b border-gray-200">
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Time<br/>stamp
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ASIN
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Current<br/>Price
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Avg 90<br/>Day Price
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Profit
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ROI<br/>%
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Monthly<br/>Sales
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ASIN<br/>Rank
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Offer<br/>Count
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Source
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Keepa<br/>Drops
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {displayedNotifications.map((notification) => (
                <tr 
                  key={notification.id}
                  onClick={() => handleRowClick({
                    asin: notification.asin,
                    title: notification.title,
                    current_price: notification.currentPrice,
                    avg_90day_price: notification.avg90DayPrice,
                    profit: notification.profit,
                    roi_percentage: notification.roiPercentage,
                    monthly_sales: notification.monthlySales,
                    category: notification.category,
                    asin_rank: notification.asinRank,
                    offer_count: notification.offerCount,
                    source: notification.source,
                    keepa_drops: notification.keepaDrops,
                    image_url: notification.imageUrl
                  })}
                  className="hover:bg-gray-50 transition-colors duration-200 relative cursor-pointer"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(notification.timestamp).toLocaleString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false
                    })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img 
                      src={`https://images-na.ssl-images-amazon.com/images/I/${notification.imageUrl}`} 
                      alt={notification.title}
                      className="h-12 w-12 object-contain"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'placeholder-image-url';
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">{notification.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{notification.asin}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatPrice(notification.currentPrice, notification.source)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    £{notification.avg90DayPrice?.toFixed(2) || '0.00'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    £{notification.profit?.toFixed(2) || '0.00'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.roiPercentage?.toFixed(2)}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.monthlySales || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.asinRank}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.offerCount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.source}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {notification.keepaDrops || '0'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap" onClick={(e) => e.stopPropagation()}>
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        checked={notification.checked || false}
                        onChange={() => handleToggleCheck(notification.id, notification.checked)}
                        className="h-4 w-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
                        title={notification.checked ? "Mark as unchecked" : "Mark as checked"}
                      />
                      <a 
                        href={`https://sas.selleramp.com/sas/lookup?asin=${notification.asin}&sas_cost_price=${notification.currentPrice}&sas_sale_price=${notification.avg90DayPrice}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900"
                        title="Analyze with SAS"
                      >
                        <FaDatabase className="h-4 w-4" />
                      </a>
                      <a 
                        href={`https://keepa.com/#!product/${getKeepaMarket(notification.source)}-${notification.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900 relative group"
                        title="View on Keepa"
                        onClick={(e) => e.stopPropagation()}
                        style={{ position: 'relative' }}
                      >
                        <FaChartLine className="h-4 w-4" />
                        <KeepaTooltip 
                          asin={notification.asin}
                          source={notification.source}
                        />
                      </a>
                      <a 
                        href={`https://${getAmazonDomain(notification.source)}/dp/${notification.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900"
                        title="View on Amazon"
                      >
                        <FaShoppingCart className="h-4 w-4" />
                      </a>
                      <button
                        onClick={() => {
                          setShowPriceModal(true);
                          setSelectedAsin(notification.asin);
                          setSelectedSource(notification.source);
                        }}
                        className="text-gray-600 hover:text-gray-900"
                        title="Add to Price Monitor"
                      >
                        <FaPlus className="h-4 w-4" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          autoSearchPrices(notification);
                        }}
                        className="text-gray-600 hover:text-gray-900"
                        title="Auto Search Prices"
                      >
                        <FaSearch className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Add pagination controls */}
          <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {Math.min((currentPage - 1) * resultsPerPage + 1, totalResults)}
                  </span>
                  {' '}-{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * resultsPerPage, totalResults)}
                  </span>
                  {' '}of{' '}
                  <span className="font-medium">{totalResults}</span>
                  {' '}results
                </p>
              </div>
              <div>
                <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    First
                  </button>
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    Next
                  </button>
                  <button
                    onClick={() => setCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                  >
                    Last
                    </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modals */}
      {showModal && renderNotificationForm()}
      {showTasks && renderTasks()}
      {showPriceModal && (
        <PriceInputModal
          isOpen={showPriceModal}
          onClose={() => setShowPriceModal(false)}
          onSubmit={handleAddMonitor}
          asin={selectedAsin}
          source={selectedSource}
        />
      )}
      {showPurchaseModal && (
        <PurchaseModal
          isOpen={showPurchaseModal}
          onClose={() => setShowPurchaseModal(false)}
          product={selectedProduct}
          onSubmit={handleAddPurchase}
        />
      )}
    </div>
  );
};

export default NotificationsView;
