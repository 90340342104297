'use client'

import { useState, useEffect } from 'react'
import { ArrowRight, ArrowDown, CheckCircle, Database, Bell, CheckSquare, Clock, Check, Store, Filter, Search, Plus, Minus } from 'lucide-react'
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function Component() {
  const [profit, setProfit] = useState(0)
  const [time, setTime] = useState(60)
  const [showTime, setShowTime] = useState(false)
  const [screen, setScreen] = useState('profit')
  const [expandedIndex, setExpandedIndex] = useState(null)
  const navigate = useNavigate();

  const handleCheckout = async () => {
    try {
        const apiEndpoint = `${window.location.origin}/api/create-checkout-session`;
        const response = await axios.post(apiEndpoint, {}, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });
        
        const stripe = await stripePromise;
        if (!response.data.sessionId) {
            throw new Error('No session ID received');
        }
        
        const { error } = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId
        });
        
        if (error) {
            console.error('Stripe error:', error);
            throw error;
        }
    } catch (error) {
        console.error('Checkout error:', error);
        alert('Failed to start checkout. Please try again.');
    }
};

  useEffect(() => {
    const profitInterval = setInterval(() => {
      setProfit(prev => {
        if (prev < 1000000) {
          return prev + 5000
        } else {
          clearInterval(profitInterval)
          setTimeout(() => {
            setScreen('time')
            setShowTime(true)
          }, 2000)
          return 1000000
        }
      })
    }, 30)

    return () => clearInterval(profitInterval)
  }, [])

  useEffect(() => {
    if (showTime) {
      const timeInterval = setInterval(() => {
        setTime(prev => {
          if (prev > 5) {
            return prev - 1
          } else {
            clearInterval(timeInterval)
            return 5
          }
        })
      }, 50)

      return () => clearInterval(timeInterval)
    }
  }, [showTime])

  const thoughts = [
    "I've been sourcing all day and not found a single profitable lead.",
    "My spend is so low this month, where am I going to find good items to buy?",
    "I've received so many notifications, I don't know where to start.",
  ]

  const features = [
    {
      icon: Database,
      title: "Amazon 2 Amazon (A2A) Database",
      description: "Browse thousands of profitable leads in our searchable database. Filter by brand, price, and profit to find profitable items within minutes."
    },
    {
      icon: Bell,
      title: "A2A Notifications",
      description: "Create custom private filters to receive only the notifications that matter to you."
    },
    {
      icon: Clock,
      title: "A2A ASIN Price/Stock Monitor",
      description: "Track the price and stock of any item in UK & EU and get instant notifications, ensuring you never miss a price drop."
    },
    {
      icon: CheckSquare,
      title: "Smart Notification Manager",
      description: "Keep your notifications organized by marking items as reviewed, helping you focus on fresh opportunities."
    },
    {
      icon: Store,
      title: "Storefront Hunter",
      description: "Monitor 15 storefronts with instant alerts and automatic international A2A checking. Turn competitor listings into your next profitable opportunity."
    },
    {
      icon: Search,
      title: "Automated Google Check (Coming Soon)",
      description: "Instant product research at scale - our system automatically checks Google for each item found on Storefront Hunter, eliminating hours of manual sourcing."
    }
  ]

  const faqs = [
    {
      question: "What marketplaces do you support?",
      answer: "We currently support Amazon marketplaces in the UK, US, Germany, France, Italy, and Spain."
    },
    {
      question: "How many ASINs can I monitor?",
      answer: "There is a current limit of 10 but will increase in the future."
    },
    {
      question: "What is an A2A lead?",
      answer: "An A2A (Amazon to Amazon) lead is a product opportunity where you can profitably source an item from Amazon and sell directly back on Amazon."
    },
    {
      question: "How often are prices updated?",
      answer: "The A2A database, A2A Notifications and ASIN Monitor are instant. Storefront Hunter is updated every 4 hours."
    },
    {
      question: "Will using Profit Hunter affect my Amazon account?",
      answer: "No, Profit Hunter is a third-party tool that doesn't interact directly with your Amazon account."
    },
    {
      question: "Can I cancel anytime?",
      answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
    },
    {
      question: "Do you offer a refund?",
      answer: "We offer a 7-day money-back guarantee for new members. If you're not satisfied with our service, you can request a full refund within the first 7 days of your subscription."
    },
    {
      question: "I have another question",
      answer: "If you have any other questions, please don't hesitate to contact our support team. We're here to help!"
    }
  ]

  const toggleQuestion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  return (
    <div className="min-h-screen bg-black text-white w-full overflow-x-hidden">
      <header className="border-b border-gray-800">
        <div className="container mx-auto px-4 py-4 max-w-7xl">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-10-25%20at%2014.27.52-9vMmrwtQ51CudUygMtHtrFcKVPiB9d.png"
                  alt="Profit Hunter Logo"
                  className="h-10 w-10"
                />
                <span className="text-xl font-semibold text-white">Profit Hunter</span>
              </div>
              <div className="md:hidden">
                <button 
                  onClick={() => navigate('/login')} 
                  className="rounded-md bg-purple-600 px-3 py-1.5 text-sm font-medium text-white hover:bg-purple-700 min-w-[80px]"
                >
                  Login
                </button>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center mt-4 md:mt-0">
              <nav className="w-full md:w-auto">
                <ul className="flex justify-center space-x-6 md:space-x-6">
                  <li>
                    <a href="#pricing" className="text-gray-300 hover:text-white text-sm md:text-base">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a href="#features" className="text-gray-300 hover:text-white text-sm md:text-base">
                      Features
                    </a>
                  </li>
                  <li>
                    <a href="#faq" className="text-gray-300 hover:text-white text-sm md:text-base">
                      FAQ
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="hidden md:block">
                <button 
                  onClick={() => navigate('/login')} 
                  className="rounded-md bg-purple-600 px-4 py-2 text-sm font-medium text-white hover:bg-purple-700 min-w-[80px]"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="w-full overflow-x-hidden">
        <section className="container mx-auto px-4 py-16 max-w-7xl">
          <div className="grid gap-12 md:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h1 className="mb-4 text-6xl font-bold leading-tight tracking-tighter">
                <span className="text-purple-400">Maximise Profits</span>,{' '}
                <span className="text-white">Minimise Time</span>.
              </h1>
              <p className="mb-8 text-xl text-gray-300">
                Discover a faster, more profitable way to source products to sell on Amazon. Our web app streamlines the process,
                helping you find high-profit items in record time.
              </p>
              <div className="mb-8">
                <button 
                  onClick={() => {
                    document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' });
                  }}
                  className="inline-flex items-center justify-center rounded-md bg-purple-600 px-4 py-2 text-sm md:text-base font-semibold text-white hover:bg-purple-700 min-w-[160px]"
                >
                  Get Profit Hunter
                  <ArrowRight className="ml-2 h-4 w-4 md:h-5 md:w-5" />
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center rounded-lg bg-gray-900 p-8">
              <div className="text-center">
                {screen === 'profit' && (
                  <>
                    <h2 className="mb-2 text-2xl font-semibold text-white">Watch profits grow</h2>
                    <div className="text-6xl font-bold text-purple-400">
                      £{profit.toLocaleString()}+
                    </div>
                    <p className="mt-2 text-gray-300">Potential profits await...</p>
                  </>
                )}
                {screen === 'time' && (
                  <>
                    <h2 className="mb-2 text-2xl font-semibold text-white">Time Spent Sourcing</h2>
                    <div className="text-6xl font-bold text-purple-400">{time} hours</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        
        <section className="mt-16 max-w-4xl mx-auto px-4">
          <h3 className="text-2xl font-bold text-gray-300 mb-4">Before Using Profit Hunter</h3>
          <div className="space-y-4 mb-8">
            {thoughts.map((thought, index) => (
              <div
                key={index}
                className="bg-gray-800 border border-gray-700 rounded-lg p-4 shadow-md"
                style={{ marginLeft: `${index * 5}%`, maxWidth: '90%' }}
              >
                <p className="text-gray-300">{thought}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-center my-8">
            <ArrowDown className="text-purple-400 h-12 w-12" />
          </div>
          <div className="bg-green-900 border border-green-700 rounded-lg p-6 shadow-md">
            <div className="flex items-start">
              <CheckCircle className="text-green-400 h-6 w-6 mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-green-300 mb-4">After Using Profit Hunter</h3>
                <ul className="list-none space-y-2">
                  <li className="flex items-start">
                    <span className="text-green-400 mr-2">-</span>
                    <p className="text-green-200">
                      I've never bought so many items. My profit this month is through the roof.
                    </p>
                  </li>
                  <li className="flex items-center mb-2">
                    <span className="text-green-400 mr-2">-</span>
                    <p className="text-green-200">
                      I am spending more than ever before whilst spending less time on my business.
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-400 mr-2">-</span>
                    <p className="text-green-200">
                      Now I manage the notifications with ease.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-16 bg-purple-900 text-white py-12">
          <div className="container mx-auto px-4 max-w-7xl">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
              <div>
                <div className="text-4xl font-bold mb-2">300,000+</div>
                <div className="text-xl">Items Monitored</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">40+</div>
                <div className="text-xl">Unique Price Monitors</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">6+</div>
                <div className="text-xl">Countries</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">100+</div>
                <div className="text-xl">Leads Found Per Day</div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="mt-16 bg-black py-16">
          <div className="container mx-auto px-4 max-w-7xl">
            <h2 className="text-4xl font-bold text-center mb-12">
              One place to find profitable items <span className="text-purple-400">effortlessly</span>.
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <div 
                  key={index} 
                  className="bg-gray-800 p-6 rounded-lg shadow-md"
                >
                  <feature.icon className="h-12 w-12 text-purple-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-white">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="mt-16 py-16 bg-gray-800">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-white">
              Profit Hunter at Your Fingertips
            </h2>
            <div className="flex justify-center items-center">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Untitled%20Design%202310x1331%20(5)-wTFyVS5YwRMhsgSUxvQUlHHueG1MdL.png"
                alt="Profit Hunter Dashboard"
                className="w-full max-w-3xl"
              />
            </div>
          </div>
        </section>

        <section className="mt-16 py-16 bg-gray-900">
          <div className="container mx-auto px-4 text-center max-w-7xl">
            <h2 className="text-6xl font-bold mb-8">
              <span className="text-purple-400">Stop</span> spending hours hunting for profits.
            </h2>
            <h2 className="text-6xl font-bold mb-12">
              Let <span className="text-purple-400">Profit Hunter</span> find them for you.
            </h2>
          </div>
        </section>

        <section id="pricing" className="mt-16 py-16 bg-black">
         <div className="container mx-auto px-4 max-w-7xl">
           <h2 className="text-4xl font-bold text-center mb-12 text-white">Turn Minutes Into Money with Profit Hunter</h2>
           <div className="max-w-md mx-auto">
             <div className="bg-gray-900 p-8 rounded-lg shadow-lg relative">
               <div className="absolute -top-3 right-8 bg-purple-600 px-4 py-1 rounded-md">
                 <span className="text-sm font-medium text-white">Launch Price</span>
               </div>
               <div className="flex items-center gap-2 mb-4">
                 <span className="text-xl font-semibold">UK</span>
                 <span className="text-xl">🇬🇧</span>
               </div>
               <p className="text-gray-300 mb-6">Earn a full time income with part time hours</p>
               <div className="flex items-baseline mb-8">
                 <span className="text-4xl font-bold text-purple-400">£49.99</span>
                 <span className="text-gray-400 ml-2">/mo</span>
                 <span className="text-gray-500 ml-2 line-through">£59.99</span>
               </div>
               <ul className="mb-8 space-y-4 text-gray-300">
                 <li className="flex items-center">
                   <Check className="h-5 w-5 text-purple-400 mr-3 flex-shrink-0" />
                   Access to A2A Database
                 </li>
                 <li className="flex items-center">
                   <Check className="h-5 w-5 text-purple-400 mr-3 flex-shrink-0" />
                   Custom Private Notifications
                 </li>
                 <li className="flex items-center">
                   <Check className="h-5 w-5 text-purple-400 mr-3 flex-shrink-0" />
                   ASIN Price/Stock Monitor in UK & EU
                 </li>
                 <li className="flex items-center">
                   <Check className="h-5 w-5 text-purple-400 mr-3 flex-shrink-0" />
                   Storefront Hunter - Monitor 15 Storefronts
                 </li>
                 <li className="flex items-center">
                   <Check className="h-5 w-5 text-purple-400 mr-3 flex-shrink-0" />
                   Automated International A2A Storefront Check
                 </li>
               </ul>
               <button 
                 onClick={handleCheckout}
                 className="w-full bg-purple-500 text-white py-3 px-4 rounded-md hover:bg-purple-600 transition duration-300 font-medium"
               >
                 Boost Your Income Now
               </button>
             </div>
           </div>
         </div>
        </section>

        <section id="faq" className="py-16 bg-black">
          <div className="container mx-auto px-4 max-w-7xl">
            <h2 className="text-2xl font-bold text-gray-200 mb-2">FAQ</h2>
            <h3 className="text-4xl font-bold text-white mb-8">Frequently Asked Questions</h3>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border-b border-gray-600">
                  <button
                    className="flex justify-between items-center w-full py-4 text-left text-gray-200 hover:text-white focus:outline-none"
                    onClick={() => toggleQuestion(index)}
                  >
                    <span className="text-lg font-medium">{faq.question}</span>
                    {expandedIndex === index ? (
                      <Minus className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Plus className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                  {expandedIndex === index && (
                    <div className="pb-4 pr-4">
                      <p className="text-gray-300">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
                    
                
                  
                  
